import React, { useState, useEffect, useMemo } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import {
	AcademicCapIcon,
	ClipboardDocumentCheckIcon,
	ClipboardDocumentListIcon,
	ExclamationTriangleIcon,
	StarIcon,
	PencilIcon,
	LightBulbIcon,
	FlagIcon
} from "@heroicons/react/24/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

import { ScheduleService } from "../../services/ScheduleService";
import { ScheduleView, ActivityType } from "../../types/ScheduleView";
import ReportCard from "../Utility/ReportCard";
import HorizontalTabs from "../Utility/HorizontalTabs";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { Button } from "../../components/shadcn-ui/Button";
import { AssignmentService } from "../../services/AssignmentService";
import { AdminAssignmentView } from "../../types/AdminAssignmentView";
import { Badge } from "../shadcn-ui/Badge";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../shadcn-ui/Chart";
import { Label as RechartsLabel, Pie, PieChart } from "recharts";
import TokenManager from "../../services/TokenManager";
import { NavigateService } from "../../services/NavigateService";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";

const EmailReportPostAssignment: React.FC = () => {
	const { debugMode } = useAppContext();
	// const { assignmentId, scheduleId } = useParams<{ assignmentId: string; scheduleId: string }>();
	const [assignmentId, setAssignmentId] = useState<string>(""); //
	const [scheduleId, setScheduleId] = useState<string>(""); //
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>(scheduleId || ""); //
	const [schedules, setSchedules] = useState<ScheduleView[]>([]);
	const [assignedSessions, setAssignedSessions] = useState<AssignedSessionView[]>([]); //
	const [assignment, setAssignment] = useState<AdminAssignmentView | null>(null); //
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);

	const { token } = useParams<{ token: string }>();
	const { scheduleToken } = useParams<{ scheduleToken: string }>();

	useEffect(() => {
		const fetchData = async () => {
			// Make the function async
			if (token && scheduleToken) {
				console.log("token", token);
				console.log("scheduleToken", scheduleToken);
				const data = await AssignmentService.getAssignmentEmail(token); // Use await
				setAssignment(data); // Update assignment state
				setAssignmentId(data.assignmentId);
				// console.log("token data", data);
				// setAssignedSessions(data.assignedSessions);
				// const assignedSessionsData = await AssignedSessionService.getByAssignmentIdEmail(token); // Use await
				// setAssignedSessions(assignedSessionsData);
				// console.log("assignment data", data);
				// console.log("scheduleToken", scheduleToken);
				const schedulesData = await ScheduleService.getByScheduleIdEmail(scheduleToken);
				setSchedules([schedulesData]);
				setScheduleId(schedulesData.scheduleId);
				setSelectedScheduleId(schedulesData.scheduleId);
			} else {
				console.log("no token");
			}
		};

		fetchData(); // Call the async function
	}, [token]);

	const tabs = schedules.map((schedule) => ({
		label: schedule.scheduleDescription,
		status: schedule.status as AssignedSessionStatus,
		date: schedule.scheduleDate,
		scheduleId: schedule.scheduleId,
		activityType: schedule.activityType
	}));

	const selectedTab = schedules.findIndex((schedule) => schedule.scheduleId === selectedScheduleId) || 0;
	const schedule = schedules.find((schedule) => schedule.scheduleId === selectedScheduleId);

	const renderGoalStatusChart = () => {
		if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

		const { goals } = schedule.goalProgress;
		const totalGoals = goals.total_count;
		const completedPercentage = totalGoals > 0 ? Math.round((goals.yes_count / totalGoals) * 100) : 0;

		const chartData = [
			{ status: "Yes", count: goals.yes_count, fill: "#00a9af" },
			{ status: "Partial", count: goals.partial_count, fill: "#a0e2e5" },
			{ status: "No", count: goals.no_count, fill: "#808080" }
		];

		const chartConfig: ChartConfig = {
			count: { label: "Count" },
			yes: { label: "Yes", color: "#00a9af" },
			partial: { label: "Partial", color: "#a0e2e5" },
			no: { label: "No", color: "#808080" }
		};

		return (
			<Card className="flex flex-col">
				<CardHeader className="items-center pb-0">
					<CardTitle className="text-[#16013e]">Goal Status</CardTitle>
				</CardHeader>
				<CardContent className="flex-1 pb-0">
					<ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[250px]">
						<PieChart>
							<ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
							<Pie data={chartData} dataKey="count" nameKey="status" innerRadius={60} strokeWidth={5}>
								<RechartsLabel
									content={({ viewBox }) => {
										if (viewBox && "cx" in viewBox && "cy" in viewBox) {
											return (
												<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
													<tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
														{completedPercentage}%
													</tspan>
													<tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
														Completed
													</tspan>
												</text>
											);
										}
									}}
								/>
							</Pie>
						</PieChart>
					</ChartContainer>
				</CardContent>
			</Card>
		);
	};

	const renderMetrics = () => {
		if (!schedule || !assignedSessions || !assignment) return null;

		// const enrolledStudents = assignedSessions.length;
		// const completedSessions = assignedSessions.filter((session) => session.status === "completed").length;
		// const completionRate = enrolledStudents > 0 ? (completedSessions / enrolledStudents) * 100 : 0;
		const completionRate = (assignment.participatedUserCount / assignment.enrolledUserCount) * 100; // TODO move this calculation to the view

		return (
			<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 my-8">
				{/* <div className="bg-white p-4 border rounded-lg border-gray-200">
					<p className="text-sm font-medium text-gray-500 mb-1">Enrolled Students</p>
					<p className="text-2xl font-bold text-[#16013e]">{enrolledStudents}</p>
				</div> */}
				<div className="bg-white p-4 border rounded-lg border-gray-200">
					<p className="text-sm font-medium text-gray-500 mb-1">Completion Rate</p>
					<p className="text-2xl font-bold text-[#16013e]">{completionRate.toFixed(2)}%</p>
				</div>
				{schedule.studentSatisfaction && schedule.activityType !== ActivityType.FIRST && (
					<div className="bg-white p-4 border rounded-lg border-gray-200">
						<p className="text-sm font-medium text-gray-500 mb-1">Student Satisfaction</p>
						<p className="text-2xl font-bold text-[#16013e]">{schedule.studentSatisfaction.average_score}/10</p>
					</div>
				)}
				{schedule.competencyProgress &&
					schedule.competencyProgress.map((competency, index) => (
						<div key={`progress-${index}`} className="bg-white p-4 border rounded-lg border-gray-200">
							<p className="text-sm font-medium text-gray-500 mb-1">Avg Rating for {competency.name}</p>
							<p className="text-2xl font-bold text-[#16013e]">{competency.average_assessment.toFixed(2)}/10</p>
						</div>
					))}
			</div>
		);
	};

	const normalizeCompetencyName = (name: string): string => {
		return name.toLowerCase().replace(/[-\s]/g, "");
	};

	const generateReportScoreData = (schedules: ScheduleView[], currentScheduleId: string) => {
		// Sort schedules by scheduleDate ascending (oldest first)
		const sortedSchedules = [...schedules].sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime());

		// Find the index of the current schedule
		const currentScheduleIndex = sortedSchedules.findIndex((schedule) => schedule.scheduleId === currentScheduleId);

		if (currentScheduleIndex === -1) return { satisfactionData: [], competencyData: {} };

		// Include all schedules up to and including the current one
		const relevantSchedules = sortedSchedules.slice(0, currentScheduleIndex + 1);

		// Extract satisfaction scores
		const satisfactionData = relevantSchedules
			.filter((schedule) => schedule.studentSatisfaction && typeof schedule.studentSatisfaction.average_score === "number")
			.map((schedule) => schedule.studentSatisfaction!.average_score);

		// Extract all unique competency names (case-insensitive and ignore hyphens)
		const competencyNames = Array.from(
			new Set(
				relevantSchedules.flatMap((schedule) =>
					schedule.competencyProgress && Array.isArray(schedule.competencyProgress)
						? schedule.competencyProgress.map((competency) => normalizeCompetencyName(competency.name))
						: []
				)
			)
		);

		// For each competency, extract assessment scores
		const competencyData: { [key: string]: number[] } = {};
		competencyNames.forEach((name) => {
			competencyData[name] = relevantSchedules
				.map((schedule) => {
					const competency = schedule.competencyProgress?.find((c) => normalizeCompetencyName(c.name) === name);
					return competency && typeof competency.average_assessment === "number" ? competency.average_assessment : null;
				})
				.filter((score): score is number => score !== null);
		});

		return { satisfactionData, competencyData };
	};

	const { satisfactionData, competencyData } = useMemo(
		() => generateReportScoreData(schedules, selectedScheduleId),
		[schedules, selectedScheduleId]
	);

	const renderStudentSatisfaction = () => {
		if (!schedule || !schedule.studentSatisfaction || !schedule.studentSatisfaction.average_score) return null;

		return (
			<ReportCard
				title="Student Satisfaction"
				icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
				reportScoreData={satisfactionData}
				lineColor="#d4d4d8"
				currentScore={schedule.studentSatisfaction.average_score}
			>
				<p className="text-gray-600">{schedule.studentSatisfaction.aggregated_summary}</p>
			</ReportCard>
		);
	};

	const renderCompetencyProgress = () => {
		if (!schedule || !schedule.competencyProgress || schedule.competencyProgress.length === 0) return null;

		return (
			<>
				{schedule.competencyProgress.map((competency, index) => {
					const normalizedName = normalizeCompetencyName(competency.name);
					const assessments = competencyData[normalizedName] || [];
					return (
						<ReportCard
							key={`competency-progress-${index}`}
							title={`Competency Progress: ${competency.name.charAt(0).toUpperCase() + competency.name.slice(1)}`}
							icon={<AcademicCapIcon className="h-5 w-5 text-[#16013e]" />}
							reportScoreData={assessments}
							lineColor="#d4d4d8"
							currentScore={competency.average_assessment}
						>
							<p className="text-gray-600">{competency.aggregated_summary}</p>
						</ReportCard>
					);
				})}
			</>
		);
	};

	const renderPurpose = () => {
		if (schedule && schedule.purpose && schedule.purpose.progress) {
			const totalCount = schedule.purpose.progress?.total_count ?? 0;
			let progressTitle = "Progress";

			if (schedule.activityType === ActivityType.MIDDLE) {
				progressTitle = "Making Progress";
			} else if (schedule.activityType === ActivityType.LAST) {
				progressTitle = "Achieved Purpose";
			}

			return (
				<ReportCard title="Purpose" icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
					<p className="text-gray-600 mb-4">{schedule.purpose.summary}</p>
					{schedule.activityType !== ActivityType.FIRST && totalCount > 0 && (
						<div className="mt-4">
							<span className="font-semibold text-gray-800 mb-2">{progressTitle}: </span>
							<div className="space-x-2 mt-2 ml-4">
								<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
									Yes: {((schedule.purpose.progress.yes_count / totalCount) * 100).toFixed(1)}%
								</div>
								<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-600">
									Partial: {((schedule.purpose.progress.partial_count / totalCount) * 100).toFixed(1)}%
								</div>
								<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
									No: {((schedule.purpose.progress.no_count / totalCount) * 100).toFixed(1)}%
								</div>
								<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
									New: {((schedule.purpose.progress.new_count / totalCount) * 100).toFixed(1)}%
								</div>
							</div>
						</div>
					)}
				</ReportCard>
			);
		}
		return null;
	};

	return (
		<div className="email-report-post-session">
			{/* <BackButton /> */}
			<div className="flex items-center justify-between mb-4">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e]">Aggregate Summary: {assignment?.assignmentTitle}</h2>

				<div className="flex items-center space-x-2">
					{TokenManager.hasAdminClearance() && assignment && (
						<Button variant="outline" onClick={() => NavigateService.navToEditAssignment(assignment.assignmentId)}>
							<PencilIcon className="h-4 w-4 mr-2" />
							Edit
						</Button>
					)}
				</div>
			</div>
			<Button variant="ghost" size="sm" className="p-0 mb-4" onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
				{isDetailsVisible ? "Hide Assignment Details" : "Show Assignment Details"}
				{isDetailsVisible ? <ChevronUpIcon className="h-4 w-4 ml-1" /> : <ChevronDownIcon className="h-4 w-4 ml-1" />}
			</Button>
			{assignment &&
				isDetailsVisible && ( // Conditional rendering based on toggle state
					<div className="text-gray-600 mb-16">
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Course: </span>
							<span className="font-normal">{assignment.courseName}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Experience Type: </span>
							<span className="font-normal">{assignment.experienceType}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Code: </span>
							<span className="font-normal">{assignment.assignmentCode}</span>
						</p>
						<p className="text-sm">{assignment.assignmentDescription}</p>

						{assignment.competencies && assignment.competencies.length > 0 && (
							<div className="text-sm mt-4 flex items-center">
								<span className="font-semibold mr-2">Competencies:</span>
								<div className="font-normal flex flex-wrap">
									{assignment.competencies.map((competency) => (
										<Badge key={competency.id} className="mr-2 border border-[#00a9af]" variant="secondary">
											{competency.name}
										</Badge>
									))}
								</div>
							</div>
						)}
					</div>
				)}

			<div>
				{assignmentId && (
					<HorizontalTabs
						tabs={tabs}
						type="admin"
						selectedTab={selectedTab}
						assignmentId={assignmentId}
						userId={null}
						onTabSelect={setSelectedScheduleId}
					/>
				)}
			</div>
			{schedule && (
				// Add padding to the top of this div
				<div key={schedule.scheduleId + assignedSessions.length} className="pt-6 mb-4">
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-2xl font-semibold">{schedule.scheduleDescription}</h2>
					</div>
					{!schedule.aggregateDataId && (
						<div className="mb-4">
							<p className="text-sm text-gray-600">
								The aggregate report will auto generate on{" "}
								{new Date(new Date(schedule.scheduleDate).getTime() + 48 * 60 * 60 * 1000).toLocaleDateString("en-US", {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								})}
							</p>
						</div>
					)}

					{renderMetrics()}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">{renderGoalStatusChart()}</div>
					<div className="mb-6">
						{schedule.flaggedComments?.negative && schedule.flaggedComments.negative.length > 0 && (
							<ReportCard title="Flagged Comments" icon={<ExclamationTriangleIcon className="h-5 w-5 text-[#16013e]" />}>
								<div className="space-y-4">
									{["high", "medium"].map((priority) => {
										const priorityComments = schedule.flaggedComments?.negative?.filter(
											(comment) => comment.priority === priority
										);
										if (priorityComments && priorityComments.length > 0) {
											return (
												<div
													key={priority}
													className={`${priority === "high" ? "bg-red-50" : "bg-yellow-50"} p-3 rounded-md`}
												>
													<h3 className={`font-semibold mb-2 ${priority === "high" ? "text-red-700" : "text-yellow-700"}`}>
														{priority === "medium" ? "Low" : priority.charAt(0).toUpperCase() + priority.slice(1)}{" "}
														Priority
													</h3>
													{priorityComments.map((comment, index) => (
														<p key={index} className="mb-2">
															• "{comment.comment}"
														</p>
													))}
												</div>
											);
										}
										return null;
									})}
								</div>
							</ReportCard>
						)}

						{renderStudentSatisfaction()}
						{renderCompetencyProgress()}

						{schedule.expectations && schedule.expectations.content && (
							<ReportCard title="Expectations" icon={<ClipboardDocumentCheckIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.expectations.content}</p>
								{schedule.expectations.outlook && schedule.expectations.outlook.total_count > 0 && (
									<div className="mt-4">
										<span className="font-semibold text-gray-800 mb-2">Outlook: </span>
										<div className="space-x-2 mt-2 ml-4">
											<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
												Positive:{" "}
												{(
													(schedule.expectations.outlook.positive_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
											<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
												Negative:{" "}
												{(
													(schedule.expectations.outlook.negative_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
											<div className="inline-block px-2 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-600">
												Mixed:{" "}
												{(
													(schedule.expectations.outlook.mixed_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
										</div>
									</div>
								)}
							</ReportCard>
						)}
						{schedule.projectGoals && (
							<ReportCard title="Project Goals" icon={<FlagIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.projectGoals}</p>
							</ReportCard>
						)}

						{renderPurpose()}

						{schedule.finalReview && schedule.finalReview.takeaways && (
							<ReportCard title="Final Review" icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}>
								{schedule.finalReview.takeaways && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Key Takeaways</h3>
										<p className="text-gray-600 ml-4">{schedule.finalReview.takeaways}</p>
									</div>
								)}
								{schedule.finalReview.growth && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Growth</h3>
										<p className="text-gray-600 ml-4">{schedule.finalReview.growth}</p>
									</div>
								)}
								{schedule.finalReview.feedback && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-4">Feedback</h3>
										<div className="space-y-3 ml-4">
											<div>
												<span className="font-medium text-gray-700">Valuable Aspects: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.valuableAspects}</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Contribution: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.contribution}</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Suggestions: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.suggestions}</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Preferred Method: </span>
												<span className="text-gray-600">
													Guided:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.guided /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%, Traditional:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.traditional /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%, Unknown:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.unknown /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</span>
											</div>
											<div>
												<span className="font-medium text-gray-700">Avg Rating for Guided Reflection: </span>
												<span className="text-gray-600">{schedule.finalReview.feedback.rating}/10</span>
											</div>
										</div>
									</div>
								)}
							</ReportCard>
						)}

						{/* {schedule.overview && (
							<ReportCard title="Overview" icon={<DocumentTextIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.overview}</p>
							</ReportCard>
						)} */}

						<div className="mb-6 flex justify-between items-center"></div>
						{/* {assignment && assignment.status !== "Drafts" ? (
							<AssignedSessionsTable
								key={assignedSessions.length}
								length={assignedSessions.length}
								assignedSessions={assignedSessions}
								onDownloadCSV={downloadAssignedSessionsCSV}
								onAddUser={handleAddUser}
								onEditUser={handleEditUser}
								onRemoveUser={handleRemoveUser}
							/>
						) : (
							<>
								<h2 className="text-xl font-semibold">Assigned Sessions</h2>

								<div className="text-gray-500 italic">
									The Assigned Sessions table will be displayed here once the assignment is no longer in draft status.
								</div>
							</>
						)} */}
					</div>

					{/* <div className="mb-6">
						<h3 className="text-lg font-semibold mb-2">Assigned Sessions</h3>
						<AssignedSessionsTable assignedSessions={assignedSessions} />
					</div> */}
				</div>
			)}
		</div>
	);
};

export default EmailReportPostAssignment;
