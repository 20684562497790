import { Type, Transform } from "class-transformer";

import { IsUUID, IsString, IsDate, IsBoolean, IsNumber, IsEnum, IsArray } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";
import { Goal, GoalProgress, CompetencyProgress, FinalReview, NoteworthyComments, Expectations, Purpose } from "./Goals";
import { ActivityType } from "../schemas/ActivityType";
import { ExperienceType } from "../schemas/ExperienceType";
import { dateTransformer } from "./dateTransformer";
import { numberTransformer } from "./numberTransformer";
import { VapiCallEndedReason } from "./vapiEndCallReasons";
import { isDateNull } from "../components/Utility/isDateNull";

interface Satisfaction {
	reason: string;
	score: number;
	best?: string;
	worst?: string;
}

export class SessionView {
	@IsUUID()
	@ViewColumn()
	sessionId!: string;

	@IsBoolean()
	@ViewColumn()
	isSubmitted!: boolean;

	@IsUUID()
	@ViewColumn()
	userId!: string;

	@IsUUID()
	@ViewColumn()
	scheduleId!: string;

	@IsString()
	@ViewColumn()
	scheduleDescription!: string;

	@IsDate()
	@ViewColumn()
	@Transform(({ value }) => dateTransformer(value))
	scheduleDate!: Date;

	@IsBoolean()
	@ViewColumn()
	isActive!: boolean;

	@IsDate()
	@ViewColumn()
	expiresAt!: Date | null;

	@IsEnum(ActivityType)
	@ViewColumn()
	activityType!: ActivityType;

	@IsUUID()
	@ViewColumn()
	assignedSessionId!: string;

	@IsUUID()
	@ViewColumn()
	assignmentId!: string;

	@IsNumber()
	@ViewColumn()
	@Transform(({ value }) => numberTransformer(value))
	grade!: number;

	@IsBoolean()
	@ViewColumn()
	isLate!: boolean;

	@IsDate()
	@ViewColumn()
	@Transform(({ value }) => dateTransformer(value))
	submittedAt!: Date;

	@IsUUID()
	@ViewColumn()
	courseId!: string;

	@IsString()
	@ViewColumn()
	courseName!: string;

	@IsString()
	@ViewColumn()
	courseCode!: string;

	@IsString()
	@ViewColumn()
	courseTerm!: string;

	@IsString()
	@ViewColumn()
	courseSection!: string;

	@IsUUID()
	@ViewColumn()
	simulationDataId!: string;

	@IsString()
	@ViewColumn()
	sessionType!: string;

	@ViewColumn()
	@Type(() => Goal)
	goals!: Goal[];

	@ViewColumn()
	goalProgress!: GoalProgress[];

	@IsString()
	@ViewColumn()
	overview!: string;

	@IsString()
	@ViewColumn()
	expectations!: Expectations;

	@IsString()
	@ViewColumn()
	satisfaction!: Satisfaction;

	@IsString()
	@ViewColumn()
	firstName!: string;

	@IsString()
	@ViewColumn()
	lastName!: string;

	@IsString()
	@ViewColumn()
	email!: string;

	@ViewColumn()
	competencyProgress!: CompetencyProgress[];

	@ViewColumn()
	purpose!: Purpose;
	@ViewColumn()
	finalReview!: FinalReview;

	@ViewColumn()
	noteworthyComments!: NoteworthyComments;

	@ViewColumn()
	@IsArray()
	@IsString({ each: true })
	recordingUrls!: string[];

	@IsBoolean()
	@ViewColumn()
	includeTranscript!: boolean;

	@IsBoolean()
	@ViewColumn()
	includeAudio!: boolean;

	@IsBoolean()
	@ViewColumn()
	includeUserIdentification!: boolean;

	@ViewColumn()
	@IsBoolean()
	userConfirmed!: boolean;

	@ViewColumn()
	endedReason!: VapiCallEndedReason;

	@ViewColumn()
	@Transform(({ value }) => numberTransformer(value))
	reviewCountYes!: number;

	@ViewColumn()
	@Transform(({ value }) => numberTransformer(value))
	reviewCountPartial!: number;

	@ViewColumn()
	@Transform(({ value }) => numberTransformer(value))
	reviewCountNo!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	reviewPercentage!: number;

	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	minutesUsed!: number;

	@Transform(({ value }) => value ?? [], { toClassOnly: true })
	@Type(() => Message)
	chatHistory!: Message[] | null;

	@Transform(({ value }) => value ?? [], { toClassOnly: true })
	@Type(() => Comment)
	comments!: Comment[] | null;

	@Transform(({ value }) => value ?? [], { toClassOnly: true })
	@Type(() => Competency)
	competencies: Competency[] = [];

	@ViewColumn()
	@IsEnum(ExperienceType)
	experienceType!: ExperienceType;

	@ViewColumn()
	@IsNumber()
	plGroupId!: number | null;

	@IsString()
	plGroupIdUrl!: string;

	@ViewColumn()
	clientId!: string;

	@ViewColumn()
	tenantId!: string;

	@ViewColumn()
	clientName!: string;

	public isNotSubmitted() {
		return isDateNull(this.submittedAt);
	}
}

export class Comment {
	id!: string;

	label!: string;

	content!: string;

	assignedSessionId!: string;

	userId!: string;

	createdAt!: Date;

	updatedAt!: Date;

	deletedAt!: Date;

	version!: number;
}

class Competency {
	id!: string;

	name!: string;
	description!: string;

	examples!: string;

	category!:
		| "Communication and collaboration"
		| "Self-management and personal development"
		| "Innovation and Problem Solving"
		| "Technology and Digital Proficiency"
		| "Custom";
}

enum MessageRole {
	System = "system",
	Assistant = "assistant",
	User = "user"
}

export class Message {
	id!: string;

	userId!: string;

	callId!: string;

	sessionId!: string;

	content!: string;

	createdAt!: Date;

	updatedAt!: Date;

	deletedAt!: Date;

	version!: number;

	role!: MessageRole;
}
