import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import {
	CheckBadgeIcon,
	ClipboardDocumentCheckIcon,
	ClipboardDocumentListIcon,
	ExclamationTriangleIcon,
	StarIcon,
	PencilIcon,
	LightBulbIcon,
	FlagIcon,
	BoltIcon,
	ClockIcon,
	UsersIcon,
	RocketLaunchIcon
} from "@heroicons/react/24/solid";
import { ClipboardIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { ScheduleService } from "../../services/ScheduleService";
import { ScheduleView, ActivityType } from "../../types/ScheduleView";
import BackButton from "../Utility/BackButton";
import ReportCard from "../Utility/ReportCard";
import HorizontalTabs from "../Utility/HorizontalTabs";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import AssignedSessionsTable from "./AssignedSessionsTable";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/shadcn-ui/Button";
import { Input } from "../../components/shadcn-ui/Input";
import { Label } from "../../components/shadcn-ui/Label";
import { Popover, PopoverTrigger, PopoverContent } from "../../components/shadcn-ui/Popover";
import { AssignmentService } from "../../services/AssignmentService";
import { AdminAssignmentView } from "@/src/types/AdminAssignmentView";
import { Badge } from "../shadcn-ui/Badge";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
// import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../shadcn-ui/Chart";
// import { Label as RechartsLabel, Pie, PieChart } from "recharts";
import TokenManager from "../../services/TokenManager";
import { NavigateService } from "../../services/NavigateService";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { ReportScoreDataPoint } from "../Utility/ReportCard";
import { SessionView } from "../../types/SessionView";
import { SessionStats, SessionService } from "../../services/SessionService";
import StudentDetailsTable from "./StudentDetailsTable";
import CustomTooltip from "../Utility/CustomTooltip";
import Spinner from "../Utility/Spinner";
import ScoreTimeline, { TimelineSegment } from "./ScoreTimeline";

const AdminAssignmentPage: React.FC = () => {
	const { debugMode } = useAppContext();
	const { assignmentId, scheduleId } = useParams<{ assignmentId: string; scheduleId: string }>();
	const [selectedScheduleId, setSelectedScheduleId] = useState<string>(scheduleId || "");
	const [schedules, setSchedules] = useState<ScheduleView[]>([]);
	const [assignedSessions, setAssignedSessions] = useState<AssignedSessionView[]>([]);
	const [anonymousAssignedSessions, setAnonymousAssignedSessions] = useState<AssignedSessionView[]>([]);

	const [isGeneratingAggregate, setIsGeneratingAggregate] = useState<boolean>(false);
	const [assignment, setAssignment] = useState<AdminAssignmentView | null>(null);
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [isLoadingUserSessions, setIsLoadingUserSessions] = useState<boolean>(false);
	const [userSessions, setUserSessions] = useState<{ [key: string]: SessionView }>({});
	const [schedule, setSchedule] = useState<ScheduleView | null>(null);
	const [expandedTables, setExpandedTables] = useState<{ [key: string]: boolean }>({});
	const [assignmentSessionStats, setAssignmentSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});

	const [scheduleSessionStats, setScheduleSessionStats] = useState<SessionStats>({
		totalDuration: "0h 0m",
		avgDuration: "0h 0m",
		sessionsStarted: 0,
		sessionsSubmitted: 0,
		uniqueStudents: 0
	});

	const shuffleArray = <T,>(array: T[]): T[] => {
		const newArray = [...array];
		for (let i = newArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[newArray[i], newArray[j]] = [newArray[j], newArray[i]];
		}
		return newArray;
	};

	useEffect(() => {
		if (assignmentId) {
			// console.log("Getting schedules for assignment:", assignmentId);
			// gets all schedules for the assignment
			ScheduleService.getByAssignmentId(assignmentId)
				.then((schedules) => {
					setSchedules(schedules);
					if (debugMode) {
						console.log("schedules", schedules);
					}
				})
				.catch((error) => {
					console.error("Error getting schedules:", error);
				});
			// gets the assignment details
			AssignmentService.getAdminViewById(assignmentId).then((assignment) => {
				if (debugMode) {
					console.log("assignment", assignment);
				}
				setAssignment(assignment);
			});
			// gets the assignment session stats
			SessionService.getSessionStatsByAssignmentId(assignmentId).then((stats) => {
				// console.log(stats);
				if (debugMode) {
					console.log("assignmentSessionStats", stats);
				}
				setAssignmentSessionStats(stats);
			});
		}
	}, [assignmentId]);

	useEffect(() => {
		let localScheduleId = scheduleId;
		if (!scheduleId && schedules.length > 0) {
			setSelectedScheduleId(schedules[0].scheduleId);
			localScheduleId = schedules[0].scheduleId;
		}

		if (localScheduleId) {
			const currentSchedule = schedules.find((s) => s.scheduleId === localScheduleId);
			setSchedule(currentSchedule || null);
			setExpandedTables({});
			// gets the assigned sessions for the schedule
			AssignedSessionService.getByscheduleId(localScheduleId)
				.then((sessions) => {
					setAssignedSessions(sessions);
					if (debugMode) {
						console.log("assignedSessions", sessions);
					}
				})
				.catch((error) => {
					console.error("Error getting user assigned sessions:", error);
				});

			// gets the anonymous assigned sessions for the schedule
			if (assignment && !assignment.includeUserIdentification) {
				AssignedSessionService.getByscheduleIdAnonymous(localScheduleId).then((sessions) => {
					const filteredSessions = sessions.filter(
						(s) => s.status === AssignedSessionStatus.SUBMITTED || s.status === AssignedSessionStatus.SUBMITTED_LATE
					);
					setAnonymousAssignedSessions(shuffleArray(filteredSessions));
				});
			}
			// gets the schedule session stats
			SessionService.getSessionStatsByScheduleId(localScheduleId).then((stats) => {
				// console.log(stats);
				setScheduleSessionStats(stats);
			});
		}
	}, [scheduleId, schedules, assignment]);

	useEffect(() => {
		if (schedule) {
			fetchUserSessionData(schedule);
		}
	}, [schedule]);

	function generateAggregateData(schedule: ScheduleView) {
		setIsGeneratingAggregate(true);
		switch (schedule.activityType) {
			case ActivityType.FIRST:
				ScheduleService.generateFirstAggregateData(schedule.assignmentId, schedule.scheduleId)
					.then((schedule) => {
						setSchedules((prevSchedules) => {
							const updatedSchedules = prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? schedule : s));
							return updatedSchedules;
						});
					})
					.catch((error) => {
						console.error("Error generating aggregate data:", error);
					})
					.finally(() => {
						setIsGeneratingAggregate(false);
					});
				break;
			case ActivityType.MIDDLE:
				ScheduleService.generateMiddleAggregateData(schedule.assignmentId, schedule.scheduleId)
					.then((schedule) => {
						setSchedules((prevSchedules) => {
							const updatedSchedules = prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? schedule : s));
							return updatedSchedules;
						});
					})
					.catch((error) => {
						console.error("Error generating aggregate data:", error);
					})
					.finally(() => {
						setIsGeneratingAggregate(false);
					});
				break;
			case ActivityType.LAST:
				ScheduleService.generateLastAggregateData(schedule.assignmentId, schedule.scheduleId)
					.then((schedule) => {
						setSchedules((prevSchedules) => {
							const updatedSchedules = prevSchedules.map((s) => (s.scheduleId === schedule.scheduleId ? schedule : s));
							return updatedSchedules;
						});
					})
					.catch((error) => {
						console.error("Error generating aggregate data:", error);
					})
					.finally(() => {
						setIsGeneratingAggregate(false);
					});
				break;
		}
	}

	const tabs = schedules.map((schedule) => ({
		label: schedule.scheduleDescription,
		status: schedule.status as AssignedSessionStatus,
		date: schedule.scheduleDate,
		scheduleId: schedule.scheduleId,
		activityType: schedule.activityType
	}));

	const selectedTab = schedules.findIndex((s) => s.scheduleId === selectedScheduleId) || 0;

	const navigateToUserReport = (assignmentId: string, userId: string, scheduleId: string) => {
		NavigateService.navToSessionSummary(assignmentId, userId, scheduleId);
	};

	const downloadAggregateTemplate = async (scheduleToDownload: ScheduleView) => {
		if (!scheduleToDownload) {
			console.log("No schedule found");
			return;
		}
		setIsDownloading(true);
		await ScheduleService.downloadAggregatePDF(scheduleToDownload.scheduleId);
		setIsDownloading(false);
	};

	const downloadAssignedSessionsCSV = async () => {
		if (!selectedScheduleId) {
			console.log("No schedule found");
			return;
		}
		ScheduleService.downloadAssignedSessionsCSV(selectedScheduleId);
	};

	const refreshAssignedSessions = async () => {
		const sessions = await AssignedSessionService.getByscheduleId(selectedScheduleId);
		setAssignedSessions(sessions);
		console.log(sessions.length);
	};

	const handleAddUser = async (email: string) => {
		if (!selectedScheduleId) {
			console.log("No schedule ID found");
			return;
		}
		if (!assignmentId) {
			console.log("No assignment ID found");
			return;
		}
		console.log("Add user", email);

		await AssignmentService.createAndAddUserToAssignment(assignmentId, email);

		console.log(assignedSessions.length);
		refreshAssignedSessions();
	};

	const handleEditUser = (userId: string) => {
		console.log("Edit user", userId);
		// Implement edit user logic here
	};

	const handleRemoveUser = async (userId: string) => {
		if (!assignmentId) {
			console.log("No assignmentId ID found");
			return;
		}
		if (!selectedScheduleId) {
			console.log("No schedule ID found");
			return;
		}
		console.log("Remove user", userId);

		try {
			await AssignmentService.removeUserFromAssignment(assignmentId, userId);
			// Refresh the assigned sessions list
			refreshAssignedSessions();
		} catch (error) {
			console.error("Error removing user:", error);
			// Handle error (e.g., show an error message to the user)
		}
	};

	const handleAddExtension = async (assignedSessionId: string, assignmentId: string, scheduleId: string, userId: string, daysToAdd: number) => {
		await AssignedSessionService.addExtension(assignedSessionId, assignmentId, scheduleId, userId, daysToAdd);
		refreshAssignedSessions();
	};

	const copyToClipboard = useCallback((text: string) => {
		navigator.clipboard.writeText(text);
		// Optionally, show a tooltip or notification that the text was copied
	}, []);

	const renderGoalStatusChart = () => {
		if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

		const { goals } = schedule.goalProgress;
		const totalGoals = goals.total_count;
		const completedPercentage = totalGoals > 0 ? Math.round((goals.yes_count / totalGoals) * 100) : 0;

		// const chartData = [
		// 	{ status: "Yes", count: goals.yes_count, fill: "#00a9af" },
		// 	{ status: "Partial", count: goals.partial_count, fill: "#a0e2e5" },
		// 	{ status: "No", count: goals.no_count, fill: "#808080" }
		// ];

		// const chartConfig: ChartConfig = {
		// 	count: { label: "Count" },
		// 	yes: { label: "Yes", color: "#00a9af" },
		// 	partial: { label: "Partial", color: "#a0e2e5" },
		// 	no: { label: "No", color: "#808080" }
		// };

		return (
			<Card>
				<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
					<FlagIcon className="h-5 w-5 text-[#16013e]" />
					<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
						Goal Completion Rate
						<CustomTooltip content="This is the percentage of goals that have been completed by students." />
					</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="text-2xl font-bold text-[#16013e]">{completedPercentage.toFixed(2)}%</div>
				</CardContent>
				{/* <CardContent className="flex-1 pb-0">
					<ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[250px]">
						<PieChart>
							<ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
							<Pie data={chartData} dataKey="count" nameKey="status" innerRadius={60} strokeWidth={5}>
								<RechartsLabel
									content={({ viewBox }) => {
										if (viewBox && "cx" in viewBox && "cy" in viewBox) {
											return (
												<text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
													<tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
														{completedPercentage}%
													</tspan>
													<tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
														Completed
													</tspan>
												</text>
											);
										}
									}}
								/>
							</Pie>
						</PieChart>
					</ChartContainer>
				</CardContent> */}
			</Card>
		);
	};

	const generateTimelineData = (type: string, competencyName?: string): TimelineSegment[] => {
		if (!userSessions || Object.keys(userSessions).length === 0) return [];

		const sessions = Object.values(userSessions);

		// Sort sessions based on type
		const sortedSessions = [...sessions].sort((a, b) => {
			switch (type) {
				case "satisfaction": {
					return (b.satisfaction?.score || 0) - (a.satisfaction?.score || 0);
				}
				case "expectations": {
					const order = { positive: 0, mixed: 1, negative: 2 };
					const aOutlook = (a.expectations?.outlook || "").toLowerCase();
					const bOutlook = (b.expectations?.outlook || "").toLowerCase();
					return order[aOutlook as keyof typeof order] - order[bOutlook as keyof typeof order];
				}
				case "purpose": {
					const order = { Yes: 0, Partial: 1, No: 2, New: 3 };
					const aProgress = a.purpose?.progress || "No";
					const bProgress = b.purpose?.progress || "No";
					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				}
				case "goalProgress": {
					const order = { Yes: 0, Partial: 1, No: 2 };

					// Determine the highest priority status for each session
					const getHighestPriorityStatus = (session: SessionView) => {
						if (!Array.isArray(session.goalProgress)) return "No";

						if (session.goalProgress.some((goal) => goal.goal_status === "Yes")) return "Yes";
						if (session.goalProgress.some((goal) => goal.goal_status === "Partial")) return "Partial";
						return "No";
					};

					const aProgress = getHighestPriorityStatus(a);
					const bProgress = getHighestPriorityStatus(b);

					return order[aProgress as keyof typeof order] - order[bProgress as keyof typeof order];
				}
				case "competency": {
					if (!competencyName) return 0;
					const aCompetency = a.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					const bCompetency = b.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					return Number(bCompetency?.assessment || 0) - Number(aCompetency?.assessment || 0);
				}
				case "finalReview": {
					return (b.finalReview?.feedback?.rating || 0) - (a.finalReview?.feedback?.rating || 0);
				}
				default:
					return 0;
			}
		});

		// Map sorted sessions to timeline segments
		return sortedSessions.map((session): TimelineSegment => {
			let value: string | number;
			let variant: "high" | "medium" | "low" | "new";

			switch (type) {
				case "satisfaction": {
					const score = session.satisfaction?.score || 0;
					value = score;
					variant = score >= 8 ? "high" : score >= 6 ? "medium" : "low";
					break;
				}
				case "purpose": {
					const progress = session.purpose?.progress || "No";
					value = progress;
					variant = progress === "Yes" ? "high" : progress === "Partial" ? "medium" : progress === "New" ? "new" : "low";
					break;
				}
				case "goalProgress": {
					if (!Array.isArray(session.goalProgress)) return { value: 0, variant: "low" };
					const progress = session.goalProgress.map((goal) => goal.goal_status).join(", ") || "No";
					value = progress;
					variant = progress.includes("Yes") ? "high" : progress.includes("Partial") ? "medium" : "low";
					break;
				}
				case "expectations": {
					const outlook = (session.expectations?.outlook || "").toLowerCase();
					value = outlook;
					variant = outlook === "positive" ? "high" : outlook === "mixed" ? "medium" : "low";
					break;
				}
				case "competency": {
					if (!competencyName) {
						value = 0;
						variant = "low";
						break;
					}
					const competency = session.competencyProgress?.find((c) => c.name.toLowerCase() === competencyName.toLowerCase());
					const score = Number(competency?.assessment || 0);
					value = score;
					variant = score >= 8 ? "high" : score >= 6 ? "medium" : "low";
					break;
				}
				case "finalReview": {
					const rating = session.finalReview?.feedback?.rating || 0;
					value = rating;
					variant = rating >= 8 ? "high" : rating >= 6 ? "medium" : "low";
					break;
				}
				default: {
					value = 0;
					variant = "low";
				}
			}

			return { value, variant };
		});
	};

	const renderMetrics = () => {
		if (!schedule || !assignedSessions) return null;

		const enrolledStudents = assignedSessions.length;
		const submittedSessions = assignedSessions.filter(
			(session) => session.status === AssignedSessionStatus.SUBMITTED || session.status === AssignedSessionStatus.SUBMITTED_LATE
		).length;
		const completionRate = enrolledStudents > 0 ? (submittedSessions / enrolledStudents) * 100 : 0;

		return (
			<div className="space-y-6 my-8">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
					<Card>
						<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
							<CheckBadgeIcon className="h-5 w-5 text-[#16013e]" />
							<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
								Session Completion Rate
								<CustomTooltip content="This is the percentage of students who have completed this reflection call." />
							</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="text-2xl font-bold text-[#16013e]">{completionRate.toFixed(2)}%</div>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="flex flex-row items-center space-y-0 space-x-2 pb-2">
							<ClockIcon className="h-5 w-5 text-[#16013e]" />
							<CardTitle className="text-sm font-medium text-gray-500 flex items-center">
								Total Usage
								<CustomTooltip content="This is the total duration of all sessions for this schedule." />
							</CardTitle>
						</CardHeader>
						<CardContent>
							<div className="text-2xl font-bold text-[#16013e]">{scheduleSessionStats.totalDuration}</div>
						</CardContent>
					</Card>

					{renderGoalStatusChart()}

					{/* {schedule.studentSatisfaction && schedule.activityType !== ActivityType.FIRST && (
						<Card>
							<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
								<CardTitle className="text-sm font-medium text-gray-500">Student Satisfaction</CardTitle>
								<StarIcon className="h-4 w-4 text-[#00a9af]" />
							</CardHeader>
							<CardContent>
								<div className="text-2xl font-bold text-[#16013e]">{schedule.studentSatisfaction.average_score}/10</div>
							</CardContent>
						</Card>
					)} */}

					{/* {schedule.competencyProgress &&
						schedule.competencyProgress.map((competency, index) => (
							<Card key={`progress-${index}`}>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<CardTitle className="text-sm font-medium text-gray-500">Avg Rating for {competency.name}</CardTitle>
									<BoltIcon className="h-4 w-4 text-[#00a9af]" />
								</CardHeader>
								<CardContent>
									<div className="text-2xl font-bold text-[#16013e]">{competency.average_assessment.toFixed(2)}/10</div>
								</CardContent>
							</Card>
						))} */}
				</div>
			</div>
		);
	};

	const normalizeCompetencyName = (name: string): string => {
		return name.toLowerCase().replace(/[-\s]/g, "");
	};

	const generateReportScoreData = (schedules: ScheduleView[], currentScheduleId: string) => {
		// Sort schedules by scheduleDate ascending (oldest first)
		const sortedSchedules = [...schedules].sort((a, b) => new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime());

		// Find the index of the current schedule
		const currentScheduleIndex = sortedSchedules.findIndex((schedule) => schedule.scheduleId === currentScheduleId);

		// If the current schedule is not found, return empty data
		if (currentScheduleIndex === -1)
			return {
				satisfactionData: [],
				satisfactionDetails: [],
				competencyData: {},
				competencyDetails: {}
			};

		// Include all schedules up to and including the current one
		const relevantSchedules = sortedSchedules.slice(0, currentScheduleIndex + 1);

		// Satisfaction data with details
		const satisfactionDetails = relevantSchedules
			.filter((schedule) => schedule.studentSatisfaction && typeof schedule.studentSatisfaction.average_score === "number")
			.map((schedule) => ({
				date: schedule.scheduleDate,
				value: schedule.studentSatisfaction!.average_score,
				scheduleDescription: schedule.scheduleDescription
			}));

		const satisfactionData = satisfactionDetails.map((detail) => detail.value);

		// Competency data with details
		// Extract all unique competency names (case-insensitive and ignore hyphens)
		const competencyNames = Array.from(
			new Set(
				relevantSchedules.flatMap((schedule) =>
					schedule.competencyProgress && Array.isArray(schedule.competencyProgress)
						? schedule.competencyProgress.map((competency) => normalizeCompetencyName(competency.name))
						: []
				)
			)
		);

		// For each competency, extract assessment scores
		const competencyData: { [key: string]: number[] } = {};
		const competencyDetails: { [key: string]: ReportScoreDataPoint[] } = {};

		competencyNames.forEach((name) => {
			const details = relevantSchedules
				.map((schedule) => {
					const competency = schedule.competencyProgress?.find((c) => normalizeCompetencyName(c.name) === name);
					if (competency && typeof competency.average_assessment === "number") {
						return {
							date: new Date(schedule.scheduleDate),
							value: competency.average_assessment,
							scheduleDescription: schedule.scheduleDescription
						};
					}
					return null;
				})
				.filter((detail): detail is { date: Date; value: number; scheduleDescription: string } => detail !== null);

			competencyDetails[name] = details;
			competencyData[name] = details.map((detail) => detail.value);
		});

		return { satisfactionData, satisfactionDetails, competencyData, competencyDetails };
	};

	const { satisfactionData, satisfactionDetails, competencyData, competencyDetails } = useMemo(
		() => generateReportScoreData(schedules, selectedScheduleId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[schedules, selectedScheduleId]
	);

	const renderStudentSatisfaction = () => {
		if (!schedule || !schedule.studentSatisfaction || !schedule.studentSatisfaction.average_score) return null;

		const isExpanded = expandedTables["satisfaction"] || false;

		// Calculate percentages
		const satisfactionSegments = generateTimelineData("satisfaction");
		const totalCount = satisfactionSegments.length;
		const highCount = satisfactionSegments.filter((s) => s.variant === "high").length;
		const mediumCount = satisfactionSegments.filter((s) => s.variant === "medium").length;
		const lowCount = satisfactionSegments.filter((s) => s.variant === "low").length;

		return (
			<ReportCard
				title="Student Satisfaction"
				icon={<StarIcon className="h-5 w-5 text-[#16013e]" />}
				reportScoreData={satisfactionData}
				reportScoreDetails={satisfactionDetails}
				lineColor="#d4d4d8"
				currentScore={schedule.studentSatisfaction.average_score}
				isAdminView={true}
				showDataTable={true}
			>
				<p className="text-gray-600">{schedule.studentSatisfaction.aggregated_summary}</p>
				<div className="mt-6">
					{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
						<div className="flex items-center space-x-2">
							<Spinner className="h-4 w-4" />
							<span className="text-sm text-gray-500">Loading student details...</span>
						</div>
					) : (
						<>
							<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
								<span className="font-semibold text-gray-800">Distribution: </span>
								<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
									<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
										High: {((highCount / totalCount) * 100).toFixed(1)}%
									</div>
									<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
										Medium: {((mediumCount / totalCount) * 100).toFixed(1)}%
									</div>
									<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
										Low: {((lowCount / totalCount) * 100).toFixed(1)}%
									</div>
								</div>
							</div>
							<div className="mb-4 mt-4">
								<ScoreTimeline segments={satisfactionSegments} />
							</div>
							<Button
								variant="outlinePrimary"
								size="sm"
								onClick={() => setExpandedTables((prev) => ({ ...prev, satisfaction: !prev.satisfaction }))}
								className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
							>
								<UsersIcon className="h-4 w-4" />
								<span>{isExpanded ? "Hide" : "Show"} Sources</span>
								{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
							</Button>
							{isExpanded && (
								<StudentDetailsTable
									sessions={Object.values(userSessions)}
									type="satisfaction"
									onNavigateToReport={navigateToUserReport}
								/>
							)}
						</>
					)}
				</div>
			</ReportCard>
		);
	};

	const renderCompetencyProgress = () => {
		if (!schedule || !schedule.competencyProgress || schedule.competencyProgress.length === 0) return null;

		const toggleTable = (competencyName: string) => {
			setExpandedTables((prev) => ({
				...prev,
				[competencyName]: !prev[competencyName]
			}));
		};

		return (
			<div className="my-12">
				{schedule.competencyProgress.map((competency, index) => {
					const normalizedName = normalizeCompetencyName(competency.name);
					const assessments = competencyData[normalizedName] || [];
					const details = competencyDetails[normalizedName] || [];
					const isExpanded = expandedTables[competency.name] || false;

					return (
						<ReportCard
							key={`competency-progress-${index}`}
							title={`Competency Progress: ${competency.name
								.split(" ")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								.join(" ")}`}
							icon={<BoltIcon className="h-5 w-5 text-[#16013e]" />}
							reportScoreData={assessments}
							reportScoreDetails={details}
							lineColor="#d4d4d8"
							currentScore={competency.average_assessment}
							isAdminView={true}
							showDataTable={true}
						>
							<p className="text-gray-600">{competency.aggregated_summary}</p>
							<div className="mt-6">
								{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
									<div className="flex items-center space-x-2">
										<Spinner className="h-4 w-4" />
										<span className="text-sm text-gray-500">Loading student details...</span>
									</div>
								) : (
									<>
										{/* Add this new section for competency distribution */}
										{(() => {
											const segments = generateTimelineData("competency", competency.name);
											const totalCount = segments.length;
											const highCount = segments.filter((s) => s.variant === "high").length;
											const mediumCount = segments.filter((s) => s.variant === "medium").length;
											const lowCount = segments.filter((s) => s.variant === "low").length;

											return (
												<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
													<span className="font-semibold text-gray-800">Distribution: </span>
													<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
														<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
															High: {((highCount / totalCount) * 100).toFixed(1)}%
														</div>
														<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
															Medium: {((mediumCount / totalCount) * 100).toFixed(1)}%
														</div>
														<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
															Low: {((lowCount / totalCount) * 100).toFixed(1)}%
														</div>
													</div>
												</div>
											);
										})()}
										<div className="mb-4 mt-4">
											<ScoreTimeline segments={generateTimelineData("competency", competency.name)} />
										</div>
										<Button
											variant="outlinePrimary"
											size="sm"
											onClick={() => toggleTable(competency.name)}
											className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
										>
											<UsersIcon className="h-4 w-4" />
											<span>{isExpanded ? "Hide" : "Show"} Sources</span>
											{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
										</Button>
										{isExpanded && (
											<StudentDetailsTable
												sessions={Object.values(userSessions)}
												type="competency"
												competencyName={competency.name}
												onNavigateToReport={navigateToUserReport}
											/>
										)}
									</>
								)}
							</div>
						</ReportCard>
					);
				})}
			</div>
		);
	};

	const renderPurpose = () => {
		if (!schedule || !schedule.purpose || !schedule.purpose.progress) return null;

		const isExpanded = expandedTables["purpose"] || false;

		return (
			<ReportCard title="Purpose" icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
				<p className="text-gray-600 mb-4">{schedule.purpose.summary}</p>
				{schedule.purpose.progress.total_count > 0 && (
					<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
						<span className="font-semibold text-gray-800">Progress: </span>
						<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
								Yes: {((schedule.purpose.progress.yes_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
								Partial: {((schedule.purpose.progress.partial_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
							<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
								No: {((schedule.purpose.progress.no_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
							</div>
							{schedule.purpose.progress.new_count > 0 && (
								<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-blue-100 text-blue-800 border-blue-300">
									New: {((schedule.purpose.progress.new_count / schedule.purpose.progress.total_count) * 100).toFixed(1)}%
								</div>
							)}
						</div>
					</div>
				)}
				{userSessions && Object.keys(userSessions).length > 0 && (
					<div className="mt-6">
						{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
							<div className="flex items-center space-x-2">
								<Spinner className="h-4 w-4" />
								<span className="text-sm text-gray-500">Loading student details...</span>
							</div>
						) : (
							<>
								<div className="mb-4">
									<ScoreTimeline segments={generateTimelineData("purpose")} />
								</div>
								<Button
									variant="outlinePrimary"
									size="sm"
									onClick={() => setExpandedTables((prev) => ({ ...prev, purpose: !prev.purpose }))}
									className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
								>
									<UsersIcon className="h-4 w-4" />
									<span>{isExpanded ? "Hide" : "Show"} Sources</span>
									{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
								</Button>
								{isExpanded && (
									<StudentDetailsTable
										sessions={Object.values(userSessions)}
										type="purpose"
										onNavigateToReport={navigateToUserReport}
									/>
								)}
							</>
						)}
					</div>
				)}
			</ReportCard>
		);
	};

	const renderGoalProgress = () => {
		if (!schedule || !schedule.goalProgress || !schedule.goalProgress.goals) return null;

		const isExpanded = expandedTables["goalProgress"] || false;

		// Aggregate goal data
		const totalGoals = schedule.goalProgress.goals.total_count;
		const yesCount = schedule.goalProgress.goals.yes_count;
		const partialCount = schedule.goalProgress.goals.partial_count;
		const noCount = schedule.goalProgress.goals.no_count;

		return (
			<ReportCard title="Goal Progress" icon={<LightBulbIcon className="h-5 w-5 text-[#16013e]" />}>
				<p className="text-gray-600 mb-4">{schedule.goalProgress.summary}</p>
				<div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:space-x-2">
					<span className="font-semibold text-gray-800">Progress: </span>
					<div className="sm:mt-0 space-y-2 sm:space-y-0 space-x-2">
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
							Yes: {((yesCount / totalGoals) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
							Partial: {((partialCount / totalGoals) * 100).toFixed(1)}%
						</div>
						<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
							No: {((noCount / totalGoals) * 100).toFixed(1)}%
						</div>
					</div>
				</div>
				<div className="mb-4 mt-4">
					<ScoreTimeline segments={generateTimelineData("goalProgress")} />
				</div>
				<Button
					variant="outlinePrimary"
					size="sm"
					onClick={() => setExpandedTables((prev) => ({ ...prev, goalProgress: !prev.goalProgress }))}
					className={`flex items-center space-x-2 mt-8 ${isExpanded ? "mb-4" : ""}`}
				>
					<UsersIcon className="h-4 w-4" />
					<span>{isExpanded ? "Hide" : "Show"} Sources</span>
					{isExpanded ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
				</Button>
				{isExpanded && (
					<StudentDetailsTable sessions={Object.values(userSessions)} type="goalProgress" onNavigateToReport={navigateToUserReport} />
				)}
			</ReportCard>
		);
	};

	const fetchUserSessionData = async (schedule: ScheduleView) => {
		if (!schedule) {
			return;
		}

		try {
			setIsLoadingUserSessions(true);
			const sessions = await SessionService.getSubmittedSessionsByScheduleId(schedule.scheduleId);

			// Convert array to map with userId as key
			const sessionsMap = sessions.reduce<{ [key: string]: SessionView }>((acc, session) => {
				if (session.userId) {
					acc[session.userId] = session;
				}
				return acc;
			}, {});

			setUserSessions(sessionsMap);
		} catch (error) {
			console.error("Error fetching submitted sessions:", error);
		} finally {
			setIsLoadingUserSessions(false);
		}
	};

	return (
		<div>
			<BackButton />
			<div className="flex items-center justify-between mb-4">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e]">Aggregate Summary: {assignment?.assignmentTitle}</h2>

				<div className="flex items-center space-x-2">
					{TokenManager.hasAdminClearance() && assignment && (
						<Button variant="outline" onClick={() => NavigateService.navToEditAssignment(assignment.assignmentId)}>
							<PencilIcon className="h-4 w-4 mr-2" />
							Edit
						</Button>
					)}
					{assignment && (
						<Popover>
							<PopoverTrigger asChild>
								<Button variant="outline">Share</Button>
							</PopoverTrigger>
							<PopoverContent className="w-80">
								<div className="space-y-4">
									<h4 className="font-medium leading-none">Share Assignment</h4>
									{assignment.status === "Drafts" ? (
										<p className="text-sm text-muted-foreground">
											This assignment is currently in draft status and cannot be shared.
										</p>
									) : (
										<>
											<div className="space-y-2">
												<Label htmlFor="link">Assignment Link</Label>
												<div className="flex items-center space-x-2">
													<Input type="text" id="link" value={assignment.getSharedLink()} readOnly />
													<Button variant="outline" size="icon" onClick={() => copyToClipboard(assignment.getSharedLink())}>
														<ClipboardIcon className="h-4 w-4" />
													</Button>
												</div>
											</div>
											<p className="text-sm text-muted-foreground">
												Share this link with students to allow them to join the assignment.
											</p>
										</>
									)}
								</div>
							</PopoverContent>
						</Popover>
					)}
				</div>
			</div>
			<Button variant="ghost" size="sm" className="p-0 mb-4" onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
				{isDetailsVisible ? "Hide Assignment Details" : "Show Assignment Details"}
				{isDetailsVisible ? <ChevronUpIcon className="h-4 w-4 ml-1" /> : <ChevronDownIcon className="h-4 w-4 ml-1" />}
			</Button>
			{assignment &&
				isDetailsVisible && ( // Conditional rendering based on toggle state
					<div className="text-gray-600 mb-16">
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Course: </span>
							<span className="font-normal">{assignment.courseName}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Experience Type: </span>
							<span className="font-normal">{assignment.experienceType}</span>
						</p>
						<p className="mb-2 text-sm font-semibold">
							<span className="font-semibold">Code: </span>
							<span className="font-normal">{assignment.assignmentCode}</span>
						</p>
						<p className="text-sm">{assignment.assignmentDescription}</p>
						<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 my-8">
							<div className="bg-white p-4 border rounded-lg border-gray-200">
								<p className="text-sm font-medium text-gray-500 mb-1">Total Usage</p>
								<p className="text-2xl font-bold text-[#16013e]">{assignmentSessionStats.totalDuration}</p>
							</div>
						</div>
						{assignment.competencies && assignment.competencies.length > 0 && (
							<div className="text-sm mt-4 flex items-center">
								<span className="font-semibold mr-2">Competencies:</span>
								<div className="font-normal flex flex-wrap">
									{assignment.competencies.map((competency) => (
										<Badge key={competency.id} className="mr-2 border border-[#00a9af]" variant="secondary">
											{competency.name}
										</Badge>
									))}
								</div>
							</div>
						)}
						{debugMode && (
							<>
								<p className="text-sm">
									plGroupIdUrl:{" "}
									<a className="underline text-blue-600" target="_blank" rel="noreferrer" href={assignment.getPlGroupIdUrl()}>
										{assignment.getPlGroupIdUrl()}
									</a>
								</p>
							</>
						)}
					</div>
				)}

			<div>
				{assignmentId && (
					<HorizontalTabs
						tabs={tabs}
						type="admin"
						selectedTab={selectedTab}
						assignmentId={assignmentId}
						userId={null}
						onTabSelect={setSelectedScheduleId}
					/>
				)}
			</div>
			{schedule && (
				<div key={schedule.scheduleId + assignedSessions.length} className="pt-6 mb-4">
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-2xl font-semibold">{schedule.scheduleDescription}</h2>
						<div className="flex gap-4">
							{debugMode && (
								<Button size="sm" onClick={() => generateAggregateData(schedule)} disabled={isGeneratingAggregate}>
									{isGeneratingAggregate ? (
										<>
											<svg
												className="animate-spin h-5 w-5 mr-2 text-white"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
											>
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path
													className="opacity-75"
													fill="currentColor"
													d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
												></path>
											</svg>
											Generating...
										</>
									) : (
										"Generate Aggregate Data"
									)}
								</Button>
							)}
							{schedule.aggregateDataId && (
								<Button
									onClick={() => downloadAggregateTemplate(schedule)}
									variant="outline"
									size="sm"
									title="Download Report"
									disabled={isDownloading}
								>
									{isDownloading ? ( // Show spinner when downloading
										<svg
											className="animate-spin h-5 w-5 mr-2 text-white"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											></path>
										</svg>
									) : (
										<ArrowDownTrayIcon className="h-5 w-5 mr-2" />
									)}
									{isDownloading ? (
										"Downloading..."
									) : (
										<>
											<span className="hidden md:inline">Download Report</span>
											<span className="md:hidden">Download</span>
										</>
									)}
								</Button>
							)}
						</div>
					</div>
					{!schedule.aggregateDataId && (
						<div className="mb-4">
							<p className="text-sm text-gray-600">
								The aggregate report will auto generate on{" "}
								{new Date(new Date(schedule.scheduleDate).getTime() + 48 * 60 * 60 * 1000).toLocaleDateString("en-US", {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								})}
							</p>
						</div>
					)}

					{renderMetrics()}
					{/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">{renderGoalStatusChart()}</div> */}
					<div className="mb-6">
						{schedule.flaggedComments?.negative && schedule.flaggedComments.negative.length > 0 && (
							<ReportCard title="Flagged Comments" icon={<ExclamationTriangleIcon className="h-5 w-5 text-[#16013e]" />}>
								<div className="space-y-4">
									{["high", "medium"].map((priority) => {
										const priorityComments = schedule.flaggedComments?.negative?.filter(
											(comment) => comment.priority === priority
										);
										if (priorityComments && priorityComments.length > 0) {
											return (
												<div
													key={priority}
													className={`${priority === "high" ? "bg-red-50" : "bg-yellow-50"} p-3 rounded-md`}
												>
													<h3 className={`font-semibold mb-2 ${priority === "high" ? "text-red-700" : "text-yellow-700"}`}>
														{priority === "medium" ? "Low" : priority.charAt(0).toUpperCase() + priority.slice(1)}{" "}
														Priority
													</h3>
													{priorityComments.map((comment, index) => (
														<p key={index} className="text-gray-700 mb-2 last:mb-0">
															• "{comment.comment}"{" "}
															{comment.firstName && comment.lastName && (
																<span className="text-sm text-gray-600">
																	(
																	<button
																		className="text-[#00a9af] hover:underline"
																		onClick={() =>
																			navigateToUserReport(assignmentId!, comment.userId, schedule.scheduleId)
																		}
																	>
																		{comment.firstName} {comment.lastName}
																	</button>
																	)
																</span>
															)}
														</p>
													))}
												</div>
											);
										}
										return null;
									})}
								</div>
							</ReportCard>
						)}

						{renderStudentSatisfaction()}
						{renderCompetencyProgress()}

						{schedule.expectations && schedule.expectations.content && (
							<ReportCard title="Expectations" icon={<ClipboardDocumentCheckIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.expectations.content}</p>
								{schedule.expectations.outlook && schedule.expectations.outlook.total_count > 0 && (
									<div className="mt-4 flex items-center space-x-2">
										<span className="font-semibold text-gray-800">Outlook: </span>
										<div className="space-x-2">
											<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
												Positive:{" "}
												{(
													(schedule.expectations.outlook.positive_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
											<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
												Negative:{" "}
												{(
													(schedule.expectations.outlook.negative_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
											<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
												Mixed:{" "}
												{(
													(schedule.expectations.outlook.mixed_count / schedule.expectations.outlook.total_count) *
													100
												).toFixed(1)}
												%
											</div>
										</div>
									</div>
								)}
								{userSessions && Object.keys(userSessions).length > 0 && (
									<div className="mt-6">
										<div className="mb-4">
											<ScoreTimeline segments={generateTimelineData("expectations")} />
										</div>
										<Button
											variant="outlinePrimary"
											size="sm"
											onClick={() => setExpandedTables((prev) => ({ ...prev, expectations: !prev.expectations }))}
											className={`flex items-center space-x-2 mt-8 ${expandedTables["expectations"] ? "mb-4" : ""}`}
										>
											<UsersIcon className="h-4 w-4" />
											<span>{expandedTables["expectations"] ? "Hide" : "Show"} Sources</span>
											{expandedTables["expectations"] ? (
												<ChevronUpIcon className="h-4 w-4" />
											) : (
												<ChevronDownIcon className="h-4 w-4" />
											)}
										</Button>
										{expandedTables["expectations"] &&
											(isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
												<div className="flex justify-center p-4">
													<Spinner />
												</div>
											) : (
												<StudentDetailsTable
													sessions={Object.values(userSessions)}
													type="expectations"
													onNavigateToReport={navigateToUserReport}
												/>
											))}
									</div>
								)}
							</ReportCard>
						)}
						{schedule.activityType === ActivityType.FIRST && schedule.projectGoals && schedule.projectGoals !== "" && (
							<ReportCard title="Project Goals" icon={<FlagIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.projectGoals}</p>
								<Button
									variant="outlinePrimary"
									size="sm"
									onClick={() => setExpandedTables((prev) => ({ ...prev, goalProgress: !prev.goalProgress }))}
									className={`flex items-center space-x-2 mt-8 ${expandedTables["goalProgress"] ? "mb-4" : ""}`}
								>
									<UsersIcon className="h-4 w-4" />
									<span>{expandedTables["goalProgress"] ? "Hide" : "Show"} Sources</span>
									{expandedTables["goalProgress"] ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
								</Button>
								{expandedTables["goalProgress"] && (
									<StudentDetailsTable
										sessions={Object.values(userSessions)}
										type="goalProgress"
										onNavigateToReport={navigateToUserReport}
									/>
								)}
							</ReportCard>
						)}

						{(schedule.activityType === ActivityType.MIDDLE || schedule.activityType === ActivityType.LAST) && renderGoalProgress()}

						{renderPurpose()}

						{schedule.finalReview && (schedule.finalReview.takeaways || schedule.finalReview.growth) && (
							<ReportCard title="Student Growth" icon={<RocketLaunchIcon className="h-5 w-5 text-[#16013e]" />}>
								{schedule.finalReview.takeaways && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Key Takeaways</h3>
										<p className="text-gray-600">{schedule.finalReview.takeaways}</p>
									</div>
								)}
								{schedule.finalReview.growth && (
									<div className="mt-4">
										<h3 className="font-semibold text-gray-800 mb-2">Growth</h3>
										<p className="text-gray-600">{schedule.finalReview.growth}</p>
									</div>
								)}
							</ReportCard>
						)}

						{schedule.finalReview && schedule.finalReview.feedback && (
							<ReportCard title="Reflection Feedback" icon={<ClipboardDocumentListIcon className="h-5 w-5 text-[#16013e]" />}>
								<div className="mt-4">
									<div className="space-y-4">
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Valuable Aspects</h3>
											<span className="text-gray-600">{schedule.finalReview.feedback.valuableAspects}</span>
										</div>
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Experience Contribution</h3>
											<span className="text-gray-600">{schedule.finalReview.feedback.contribution}</span>
										</div>
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Suggestions</h3>
											<span className="text-gray-600">{schedule.finalReview.feedback.suggestions}</span>
										</div>
										<div className="flex items-center space-x-2">
											<h3 className="font-semibold text-gray-800 mb-2">Avg Rating for Guided Reflection</h3>
											<Badge
												variant={
													schedule.finalReview.feedback.rating >= 8
														? "high"
														: schedule.finalReview.feedback.rating >= 6
														? "medium"
														: "low"
												}
												className="text-sm font-medium"
											>
												{schedule.finalReview.feedback.rating}
											</Badge>
										</div>
										<div>
											<h3 className="font-semibold text-gray-800 mb-2">Preferred Method</h3>
											<div className="space-x-2">
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#e6f7f7] text-[#00a9af] border-[#b3e6e8]">
													Guided:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.guided /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</div>
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-[#FEF3F2] text-[#B42318] border-[#FEE4E2]">
													Traditional:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.traditional /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</div>
												<div className="inline-block px-2 py-1 rounded-md border text-sm font-medium bg-yellow-100 text-yellow-600 border-yellow-300">
													Unknown:{" "}
													{(
														(schedule.finalReview.feedback.preferredMethod.unknown /
															schedule.finalReview.feedback.preferredMethod.total) *
														100
													).toFixed(1)}
													%
												</div>
											</div>
										</div>
									</div>
								</div>
								{userSessions && Object.keys(userSessions).length > 0 && (
									<div className="mt-6">
										{isLoadingUserSessions || Object.keys(userSessions).length === 0 ? (
											<div className="flex items-center space-x-2">
												<Spinner className="h-4 w-4" />
												<span className="text-sm text-gray-500">Loading student details...</span>
											</div>
										) : (
											<>
												<div className="mb-4">
													<ScoreTimeline segments={generateTimelineData("finalReview")} />
												</div>
												<Button
													variant="outlinePrimary"
													size="sm"
													onClick={() => setExpandedTables((prev) => ({ ...prev, finalReview: !prev.finalReview }))}
													className={`flex items-center space-x-2 mt-8 ${expandedTables["finalReview"] ? "mb-4" : ""}`}
												>
													<UsersIcon className="h-4 w-4" />
													<span>{expandedTables["finalReview"] ? "Hide" : "Show"} Sources</span>
													{expandedTables["finalReview"] ? (
														<ChevronUpIcon className="h-4 w-4" />
													) : (
														<ChevronDownIcon className="h-4 w-4" />
													)}
												</Button>
												{expandedTables["finalReview"] && (
													<StudentDetailsTable
														sessions={Object.values(userSessions)}
														type="finalReview"
														onNavigateToReport={navigateToUserReport}
													/>
												)}
											</>
										)}
									</div>
								)}
							</ReportCard>
						)}

						{/* {schedule.overview && (
							<ReportCard title="Overview" icon={<DocumentTextIcon className="h-5 w-5 text-[#16013e]" />}>
								<p>{schedule.overview}</p>
							</ReportCard>
						)} */}

						<div className="mb-6 flex justify-between items-center"></div>
						{assignment && assignedSessions && (
							<AssignedSessionsTable
								key={assignedSessions.length}
								length={assignedSessions.length}
								assignedSessions={assignedSessions}
								onDownloadCSV={downloadAssignedSessionsCSV}
								onAddUser={handleAddUser}
								onEditUser={handleEditUser}
								onRemoveUser={handleRemoveUser}
								onAddExtension={handleAddExtension}
								anonymous={assignment.includeUserIdentification ? null : false}
							/>
						)}

						{assignment && !assignment.includeUserIdentification && anonymousAssignedSessions && (
							<div className="mt-8 mb-6">
								<AssignedSessionsTable
									key={anonymousAssignedSessions.length}
									length={anonymousAssignedSessions.length}
									assignedSessions={anonymousAssignedSessions}
									onDownloadCSV={downloadAssignedSessionsCSV}
									onAddUser={handleAddUser}
									onEditUser={handleEditUser}
									onRemoveUser={handleRemoveUser}
									onAddExtension={handleAddExtension}
									anonymous={true}
								/>
							</div>
						)}
					</div>

					{/* <div className="mb-6">
						<h3 className="text-lg font-semibold mb-2">Assigned Sessions</h3>
						<AssignedSessionsTable assignedSessions={assignedSessions} />
					</div> */}
				</div>
			)}
		</div>
	);
};

export default AdminAssignmentPage;
