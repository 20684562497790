import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon, PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useFormContext } from "../FormContext";
import { useFormikContext } from "formik";
import { Button } from "../../../shadcn-ui/Button";
import CustomTooltip from "../../../Utility/CustomTooltip";

// Define the structure of a session
interface Session {
	title: string;
	date: Date | null;
	startDate: Date | null;
}

// Define the structure of form values
interface Values {
	sessions: Session[];
	[key: string]: any;
}

// Define props for the ReflectionDates component
export interface ReflectionDatesProps {
	values: Values;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	updateFormValues: (values: any) => void;
	isValid: boolean;
	dirty: boolean;
}

// Define a helper component for DateRangePicker
interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onStartDateChange: (date: Date | null) => void;
	onEndDateChange: (date: Date | null) => void;
	disabled?: boolean;
	minDate?: Date;
	maxDate?: Date;
}

// Update the DateRangePicker component
const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onStartDateChange, onEndDateChange, disabled, minDate, maxDate }) => {
	// Convert null to undefined for react-datepicker
	const convertNullToUndefined = (date: Date | null): Date | undefined => {
		return date || undefined;
	};

	// Helper function to set default start date
	const handleEndDateChange = (date: Date | null) => {
		onEndDateChange(date);
		// Only set default start date if there isn't already a start date
		// or if the current start date is after the new end date
		if (date) {
			if (!startDate || startDate > date) {
				const defaultStartDate = new Date(date);
				defaultStartDate.setDate(defaultStartDate.getDate() - 5);
				onStartDateChange(defaultStartDate);
			}
		}
	};

	// Validate start date is not after end date
	const handleStartDateChange = (date: Date | null) => {
		if (date && endDate && date > endDate) {
			// If selected start date is after end date, don't allow it
			return;
		}
		onStartDateChange(date);
	};

	return (
		<div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
			<div className="flex-1">
				<label className="block text-sm font-medium text-gray-700 mb-1">Due Date*</label>
				<div className="relative">
					<DatePicker
						selected={endDate}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						minDate={minDate}
						maxDate={maxDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select due date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
			<div className="flex-1">
				<label className="block text-sm font-medium text-gray-700 mb-1">Start of Availability Window*</label>
				<div className="relative">
					<DatePicker
						selected={startDate}
						onChange={handleStartDateChange}
						selectsStart
						startDate={convertNullToUndefined(startDate)}
						endDate={convertNullToUndefined(endDate)}
						maxDate={endDate || undefined}
						minDate={minDate}
						disabled={disabled}
						className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-10 pr-3 py-2 text-gray-900"
						placeholderText="Select start date"
					/>
					<CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

// ReflectionDates component for configuring reflection sessions
const ReflectionDates: React.FC<ReflectionDatesProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onNext,
	onPrevious,
	updateFormValues,
	isValid,
	dirty
}) => {
	const { updateFormValues: contextUpdateFormValues } = useFormContext();
	const { values: formikValues } = useFormikContext<any>();
	const [maxSessionLength, setMaxSessionLength] = useState(20);

	// Ensure values.sessions is always an array
	const sessions = Array.isArray(values.sessions) ? values.sessions : [];

	// Initialize sessions if not already set
	useEffect(() => {
		if (!sessions || sessions.length < 3) {
			setFieldValue("sessions", [
				{ title: "Pre-Reflection", date: null, startDate: null },
				{ title: "Reflection 1", date: null, startDate: null },
				{ title: "Final Reflection", date: null, startDate: null }
			]);
		}
	}, [sessions, setFieldValue]);

	// Helper function to get session date
	const getSessionDate = (index: number): Date | null | undefined => {
		return sessions && sessions[index] ? sessions[index].date : undefined;
	};

	// Check if the form is valid
	const isFormValid = () => {
		if (isPublished) return true;
		// Check if all sessions have a date and start date
		return sessions?.every((session) => session?.date && session?.startDate) ?? false;
	};

	// Determine if editing is allowed for different reflection sessions
	const canEditReflection1 = !!getSessionDate(0);
	const canEditFinalReflection = !!getSessionDate(sessions.length - 2);

	// Handle next button click
	const handleNext = () => {
		contextUpdateFormValues(values);
		onNext();
	};

	// Calculate estimated service hours
	const totalStudents = formikValues.estimatedStudents || 0;
	const totalSessions = sessions ? sessions.filter((session) => session.date).length : 0;
	const estimatedServiceTime = 12; // minutes

	const estimatedServiceHours = (totalStudents * totalSessions * estimatedServiceTime) / 60;
	const maximumServiceHours = (totalStudents * totalSessions * maxSessionLength) / 60;

	const isPublished = values.isPublished;

	// Helper function to set default start date
	const setDefaultStartDate = (dueDate: Date | null, index: number) => {
		if (dueDate) {
			const defaultStartDate = new Date(dueDate);
			defaultStartDate.setDate(defaultStartDate.getDate() - 5);
			setFieldValue(`sessions[${index}].startDate`, defaultStartDate);
		} else {
			setFieldValue(`sessions[${index}].startDate`, null);
		}
	};

	// Update the handleDateChange function
	const handleDateChange = (index: number, date: Date | null) => {
		if (!date) return;

		const updatedSessions = [...sessions];
		updatedSessions[index] = {
			...updatedSessions[index],
			date: date
		};

		// Set default start date for the current session
		setDefaultStartDate(date, index);

		// Clear dates for all subsequent sessions, including the final reflection
		for (let i = index + 1; i < updatedSessions.length; i++) {
			updatedSessions[i] = {
				...updatedSessions[i],
				date: null,
				startDate: null
			};
		}

		setFieldValue("sessions", updatedSessions);
	};

	// Update the addNewReflectionCall function
	const addNewReflectionCall = () => {
		const updatedSessions = [...sessions];
		const newIndex = updatedSessions.length - 1;

		// Add new mid-reflection session
		updatedSessions.splice(newIndex, 0, { title: `Reflection ${newIndex}`, date: null, startDate: null });

		// Clear final reflection dates and any subsequent mid-reflection dates
		for (let i = newIndex; i < updatedSessions.length; i++) {
			updatedSessions[i] = {
				...updatedSessions[i],
				date: null,
				startDate: null
			};
		}

		setFieldValue("sessions", updatedSessions);
	};

	// Update the validateSessionDate function
	const validateSessionDate = (index: number, date: Date | null) => {
		if (!date) return "Date is required";

		const sessions = values.sessions || [];

		if (index === 0) {
			// Pre-reflection: no previous date to check
			return undefined;
		} else if (index === sessions.length - 1) {
			// Final reflection: must be after the last mid-reflection
			const lastMidReflectionDate = sessions[sessions.length - 2]?.date;
			if (lastMidReflectionDate && date <= lastMidReflectionDate) {
				return "Final reflection date must be after the last mid-reflection date";
			}
		} else {
			// Mid-reflections: must be after previous reflection and before next reflection
			const previousDate = sessions[index - 1]?.date;
			const nextDate = sessions[index + 1]?.date;
			if (previousDate && date <= previousDate) {
				return "Date must be after the previous reflection date";
			}
			if (nextDate && date >= nextDate) {
				return "Date must be before the next reflection date";
			}
		}

		return undefined;
	};

	// Add this helper function at the beginning of the component
	const getFinalReflectionIndex = () => sessions.length - 1;

	return (
		<div className="space-y-8">
			<FieldArray name="sessions">
				{({ push, remove }) => (
					<div className="space-y-8">
						{/* Pre-Reflection Call */}
						<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
							<div className="flex items-center mb-4">
								<h3 className="text-lg font-medium text-gray-900">1. Choose a Due Date for the Pre-Reflection Call</h3>
								<CustomTooltip content="This call sets the foundation for the reflection process, allowing students to prepare their thoughts and expectations." />
							</div>
							<p className="text-sm text-gray-600 mb-4">Students will have until midnight eastern time to complete their call</p>
							<Field name="sessions[0]">
								{({ field, form }: any) => (
									<DateRangePicker
										startDate={values.sessions[0]?.startDate || null}
										endDate={values.sessions[0]?.date || null}
										onStartDateChange={(date) => setFieldValue("sessions[0].startDate", date)}
										onEndDateChange={(date) => handleDateChange(0, date)}
										disabled={isPublished}
										minDate={new Date()}
										maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
									/>
								)}
							</Field>
							<ErrorMessage name="sessions[0].date" component="div" className="mt-1 text-sm text-red-600" />
							<ErrorMessage name="sessions[0].startDate" component="div" className="mt-1 text-sm text-red-600" />
						</div>

						{/* Middle Reflection Calls */}
						<div className={`bg-white border border-[#eaecf0] rounded-lg p-6 ${!canEditReflection1 ? "opacity-50" : ""}`}>
							<div className="flex items-center mb-4">
								<h3 className="text-lg font-medium text-gray-900">2. Choose a Due Date for the Middle Reflection Call(s)</h3>
								<CustomTooltip
									content="This call allows students to reflect on their initial experiences and learning progress."
									// className="ml-2"
								/>
							</div>
							{sessions?.slice(1, -1).map((session: Session, index: number) => (
								<div key={index + 1} className="flex items-start bg-gray-50 p-4 rounded-md mb-4">
									<div className="flex-grow">
										<Field name={`sessions[${index + 1}]`}>
											{({ field, form }: any) => (
												<DateRangePicker
													startDate={values.sessions[index + 1]?.startDate || null}
													endDate={values.sessions[index + 1]?.date || null}
													onStartDateChange={(date) => setFieldValue(`sessions[${index + 1}].startDate`, date)}
													onEndDateChange={(date) => handleDateChange(index + 1, date)}
													disabled={isPublished}
													minDate={getSessionDate(index) || new Date()}
													maxDate={getSessionDate(index + 2) || undefined}
												/>
											)}
										</Field>
										<ErrorMessage name={`sessions[${index + 1}].date`} component="div" className="mt-1 text-sm text-red-600" />
										<ErrorMessage
											name={`sessions[${index + 1}].startDate`}
											component="div"
											className="mt-1 text-sm text-red-600"
										/>
									</div>
									{index > 0 && (
										<button
											type="button"
											onClick={() => remove(index + 1)}
											className="mt-8 ml-4 text-gray-400 hover:text-gray-600"
										>
											<TrashIcon className="h-5 w-5" />
										</button>
									)}
								</div>
							))}
							{sessions?.length < 11 && !isPublished && (
								<div className="flex items-center mt-4">
									<Button variant="outlinePrimary" onClick={addNewReflectionCall} className="text-primary-900 border-primary-900">
										<PlusIcon className="h-5 w-5 mr-2 text-primary-900" />
										<span className="text-primary-900">Add Another Reflection Call</span>
									</Button>
								</div>
							)}
						</div>

						{/* Final Reflection Call */}
						<div className={`bg-white border border-[#eaecf0] rounded-lg p-6 ${!canEditFinalReflection ? "opacity-50" : ""}`}>
							<div className="flex items-center mb-4">
								<h3 className="text-lg font-medium text-gray-900">3. Choose a Due Date for the Final Reflection Call</h3>
								<CustomTooltip content="This call allows students to reflect on their overall learning experience and outcomes." />
							</div>
							<Field name={`sessions[${getFinalReflectionIndex()}]`}>
								{({ field, form }: any) => (
									<DateRangePicker
										startDate={values.sessions[getFinalReflectionIndex()]?.startDate || null}
										endDate={values.sessions[getFinalReflectionIndex()]?.date || null}
										onStartDateChange={(date) => setFieldValue(`sessions[${getFinalReflectionIndex()}].startDate`, date)}
										onEndDateChange={(date) => handleDateChange(getFinalReflectionIndex(), date)}
										disabled={!canEditFinalReflection || isPublished}
										minDate={(() => {
											const previousDate = getSessionDate(getFinalReflectionIndex() - 1);
											if (previousDate instanceof Date) {
												return new Date(previousDate.getTime() + 86400000); // Add one day
											}
											return new Date();
										})()}
										maxDate={undefined}
									/>
								)}
							</Field>
							<ErrorMessage
								name={`sessions[${getFinalReflectionIndex()}].date`}
								component="div"
								className="mt-1 text-sm text-red-600"
							/>
							<ErrorMessage
								name={`sessions[${getFinalReflectionIndex()}].startDate`}
								component="div"
								className="mt-1 text-sm text-red-600"
							/>
						</div>
					</div>
				)}
			</FieldArray>

			{/* Service Hours Calculator Panel */}
			<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
				<h3 className="text-lg font-medium text-gray-900 mb-4">Estimated Service Hours for this Assignment</h3>
				<div className="space-y-3">
					<div className="flex justify-between items-center">
						<label className="text-sm font-medium text-gray-700">Total Number of Students:</label>
						<p className="text-lg font-medium text-gray-900">{totalStudents}</p>
					</div>
					<div className="flex justify-between items-center">
						<label className="text-sm font-medium text-gray-700">Number of Calls per Student:</label>
						<p className="text-lg font-medium text-gray-900">{totalSessions}</p>
					</div>
					<div className="flex justify-between items-center">
						<label className="text-sm font-medium text-gray-700">Estimated Duration of each Call:</label>
						<p className="text-lg font-medium text-gray-900">{estimatedServiceTime} minutes</p>
					</div>
					<div className="flex justify-between items-center">
						<label className="text-lg font-semibold text-gray-800">Estimated Service Hours:</label>
						<p className="text-2xl font-bold text-[#00a9af]">{estimatedServiceHours.toFixed(2)}</p>
					</div>
				</div>

				{/* Footnote section */}
				<div className="mt-6 bg-background p-4 rounded-md">
					<div className="flex justify-between items-center mb-2">
						<label className="text-sm font-medium text-gray-700">Adjust Estimated Call Duration:</label>
						<div className="flex items-center">
							<input
								type="number"
								value={maxSessionLength}
								onChange={(e) => setMaxSessionLength(Math.min(45, Math.max(1, parseInt(e.target.value) || 1)))}
								min="1"
								max="45"
								className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
							/>
							<span className="ml-2">minutes</span>
						</div>
					</div>
					<p className="text-xs text-gray-500 mb-2">
						This field is for estimating purposes only. ßBy default, InStage has a call length max cap of 20 minutes. This should allow
						the student to retry the call at least once.
					</p>
					<div className="flex justify-between items-center">
						<label className="text-sm font-medium text-gray-700">Maximum Service Hours:</label>
						<p className="text-lg font-medium text-gray-900">{maximumServiceHours.toFixed(2)}</p>
					</div>
				</div>
			</div>

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					variant="secondary"
					onClick={() => {
						contextUpdateFormValues(values);
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button onClick={handleNext} disabled={!isFormValid()}>
					Next
				</Button>
			</div>
		</div>
	);
};

export default ReflectionDates;
